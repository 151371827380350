.slide-in {
	opacity: 0;
	transform: translateX(80%);
	transition: opacity 2s, transform 2s;
}

.slide-in.is-in-viewport {
	opacity: 1;
	transform: translateX(0);
}
.slide-out {
	opacity: 0;
	transform: translateX(-80%);
	transition: opacity 1s, transform 2s;
}

.slide-out.is-in-viewport {
	opacity: 1;
	transform: translateX(0);
}
.fade-in {
	opacity: 0;
	transform: translateY(80%);
	transition: opacity 0.1s, transform 1s;
}

.fade-in.is-in-viewport {
	opacity: 1;
	transform: translateY(0);
}

.image-list-item {
	transition: all 0.3s ease-out;
	filter: grayscale(80%);
}

.image-list-item:hover {
	filter: grayscale(0%);
	transform: scale3d(1.1, 1.1, 1.1);
}
