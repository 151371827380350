.link {
	text-decoration: none;
	color: #fff;
	padding: 0px 15px;
	font-size: clamp(0.9rem, 1vw, 1.1rem);
	font-weight: 700;
	transition: all 0.2s ease-out;
}
.mobile-menu-link {
	text-decoration: none;
	color: #333333;
	padding: 0px 15px;
	font-size: clamp(0.9rem, 1.1vw, 1.2rem);
	font-weight: 700;
	transition: all 0.2s ease-out;
}
.link:hover {
	color: #0c659a;
	transform: scale3d(1.1, 1.1, 1.1);
}
.sticky-link {
	text-decoration: none;
	color: "white";
	background-color: "transparent";
	color: #fff;
	padding: 0px 15px;
	font-size: clamp(0.9rem, 1vw, 1.1rem);
	font-weight: 700;
	transition: all 0.4s ease-out;
}
.sticky-link:hover {
	transform: scale3d(1.2, 1.2, 1.2);
}

.map-container {
	width: 100%;
	height: 100%;
}

.container-style {
	width: 38.9dvw;
	height: 55dvh;
	border: 15px solid #fff;
}
.full-width-container-style {
	width: 99dvw;
	height: 70dvh;
	border: 2px solid lightgray;
}
