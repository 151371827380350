.fade-in {
	opacity: 0;
	transform: translateY(60%);
	transition: opacity 0.5s, transform 0.5s;
}

.fade-in.is-in-viewport {
	opacity: 1;
	transform: translateY(0);
}
.section-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}
.section-image {
	width: 100%;
	max-width: 100%;
	height: auto;
	min-height: 30dvh;
}
