.fade-in {
	opacity: 0;
	transform: translateY(100px);
	transition: opacity 1s, transform 1s;
}

.fade-in.is-in-viewport {
	opacity: 1;
	transform: translateY(0);
}
